import { Slider } from "antd";
import React, { useEffect, useState } from "react";
import {
	ProductImage,
	ToastAlertError,
	ToastAlertSuccess,
} from "../../../reauseble";
import CoreHttpHandler from "../../../http/services/CoreHttpHandler";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { BeatLoader } from "react-spinners";
import Select from 'react-select';


const Filter = ({ data, getProducts, loading, discountedProducts }) => {
	const [email, setEmail] = useState("");
	const [singleCategoryValue, setSingleCategoryValue] = useState("");
	const [categoryValue, setCategoryValue] = useState("");
	const [selectedValues, setSelectedValues] = useState([]);
	const [subCategoriesData, setSubCategoriesData] = useState([]);
	const [loading1, setLoading1] = useState(false);


	const navigate = useNavigate();
	const options = [
		{ value: 'chocolate', label: 'Chocolate' },
		{ value: 'strawberry', label: 'Strawberry' },
		{ value: 'vanilla', label: 'Vanilla' },
	];


	useEffect(() => {
		if (singleCategoryValue) {

			CoreHttpHandler.request(
				"products",
				"allSubCatProducts",
				{

					product_category_id: singleCategoryValue
				},
				(response) => {

					let cate = [];
					response.data.data.data.data.map((r) => {
						cate.push({
							value: r.id,
							label: r.name,
						});
					});

					setSubCategoriesData(cate);
				},
				(err) => { }
			);
		}
	}, [singleCategoryValue])


	useEffect(() => {
	}, [subCategoriesData])
	const handleNewletter = (e) => {
		if (email) {
			setLoading1(true);
			e.preventDefault();
			CoreHttpHandler.request(
				"forms",
				"newsletter",
				{
					email: email,
				},
				(response) => {
					setLoading1(false);
					setEmail("");
					ToastAlertSuccess("You have subscribed for new updates ");
				},
				(err) => {
					ToastAlertError(
						err?.response?.data?.message
							? err?.response.data.message
							: "something went wrong"
					);
					setLoading1(false);
				}
			);
		}
	};

	const handleSelectChange = (event) => {


		let arr = [];

		let mapArray = event.map((e) => {
			arr.push(e.value)

		})
		getProducts(singleCategoryValue, 0, true, arr)

	};
	const customStyles = {
		dropdownIndicator: (provided) => ({
		  ...provided,
		  "svg": {
			fill: "red"
		  }
		}),
	  }
	return (
		<>
			{/*  getProducts(e[0]?.id, 0); setCategoryValue([...categoryValue,e[0]?.name]); console.log(e)  */}
			<div className='filter_wrap'>
				<div className='inner_wrap'>
					<div className='cat_wrap'>
						<h4>Categories</h4>
						<ul className='cat_list' style={{ paddingRight: "0.5em" }}>
							<li
								onClick={() => getProducts(null, 0, true)}
								style={{ textTransform: "capitalize" }}>
								<a style={{ color: "#f3ac3b" }}> All</a>
							</li>
							<div className='prod_wrap' style={{ marginLeft: "0%", marginTop: "5%" }}>
								<div className='top_wrap' >

									<div className='prod_sort_wrap' >

										<form action=''>

											<select
												style={{ width: "225px", maxWidth: "225px" }}
												// name=''
												onChange={(e) => { getProducts(e.target.value, 0); setSingleCategoryValue(e.target.value) }}>
												<option style={{ textTransform: "capitalize" }} >Select Category</option>
												{data?.map((val) => {
													return (<>
														<option key={val.id} style={{ textTransform: "capitalize" }} value={val.id} ><input type="checkbox" style={{ backgroundColor: "white" }} />{val.name}</option>
													</>);
												})}
											</select>

										</form>
									</div>
								</div>
							</div>
							{singleCategoryValue ?
								<Select isMulti
							
									styles={{
										customStyles,
										dropdownIndicator: (provided) => ({
											...provided,
											color: '#f3ac3b',
											':hover': {
											  color: 'white',
											},
										  }),
										option: (provided, state) => ({
											...provided,
											color: "#8c8c8c",
											color:state.isFocused ? "white" : "#8c8c8c",
											
										  }),
										control: (baseStyles, state) => ({
											...baseStyles,
											borderColor: state.isFocused ? '#393939' : '#393939',
											backgroundColor: state.isFocused ? "#393939" : "#393939",
											maxWidth: "225px",
											margin: "auto",
											".css-1p3m7a8-multiValue":{
												backgroundColor:"hsl(36.85deg 88.46% 59.22%)"
											}
											
										}),
									
									}}
									theme={(theme) => ({
										...theme,
										colors: {
											...theme.colors,
											text: 'orangered',
											primary25: '#f3ac3b',
											primary: 'black',
											neutral0: '#393939',
											

											
										},

									})}
									placeholder="Select Sub Category"
									closeMenuOnSelect={false}
									hideSelectedOptions={false}
									onChange={(e) => { handleSelectChange(e) }}
									options={subCategoriesData}
								/>
								: null}
						</ul>
					</div>

					<div className='discnt_prod_list'>
						<h4>Discounted Products</h4>
						<ul className='sidebar_prod_list'>
							{!loading && discountedProducts
								? discountedProducts.map((val) => {
									return (
										<li
											key={val.id}
											style={{ cursor: "pointer" }}
											onClick={() => navigate(`/singleproduct/${val.id}`)}>
											<div className='img_wrap'>
												<img
													style={{ height: "auto", width: "100%" }}
													src={
														val.featured_image
															? `http://upload.pegasusdeliveryllc.com/v1/fetch/file/${val.featured_image}`
															: ProductImage
													}
													alt='featured image'
												/>
											</div>
											<div
												className='price_wrap'
												style={{ marginTop: 0, paddingTop: 0 }}>
												<Link to={`/singleproduct/${val.id}`}>
													<h6>{val?.name}</h6>
												</Link>
												<span className='regular_price'>
													<span className='curreny'>$</span>
													{val?.price}
												</span>

												<span className='discount_price'>
													<span className='curreny'>$</span>
													{val?.sale_price}
												</span>
											</div>
										</li>
									);
								})
								: null}
						</ul>
					</div>
					<div className='newsletter_wrap'>
						<h4>Newsletter</h4>
						<form action=''>
							<input
								type='email'
								className='email_fld'
								required
								placeholder='Email'
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
							{!loading1 && (
								<input
									className='btn_fld'
									type='submit'
									onClick={handleNewletter}
								/>
							)}
							<BeatLoader
								color={"black"}
								loading={loading1}
								size={13}
								aria-label='Loading Spinner'
								data-testid='loader'
							/>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default Filter;
