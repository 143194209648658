import React from "react";
import {Contactsec1} from './components/Contactcomponent';

const Contact = () => {
  return (
    <>
      <div id="contact_main">
        <div className="content_wrap">
          <Contactsec1 />
        </div>
      </div>
    </>
  );
};

export default Contact;