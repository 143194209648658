import { useState, useEffect, useCallback } from "react";
import Routes from "./Routes";
import CoreHttpHandler from "./http/services/CoreHttpHandler";
import { useLocation } from "react-router-dom";
import { ScrollUp } from "./reauseble";
import { ToastContainer } from "react-toastify";
const App = () => {
	const [products, setProducts] = useState([]);
	const [totalItems, setTotalItems] = useState(0);
	const [loading, setLoading] = useState(true);
	const [sortBy, setSortBy] = useState("desc");
	const [searchVal, setSearchVal] = useState("");
	const [searchPro, setSearchPro] = useState([]);
	const [currentParams, setCurrentParams] = useState({
		limit: 9,
		page: 0,
	});
	const [selectedCategory, setSelectedCategory] = useState("");

	const location = useLocation();

	const getProducts = useCallback((selectedCate, page, all,subCat) => {
				
		if (selectedCate !== selectedCategory && selectedCate != undefined) {
			setSelectedCategory(selectedCate);
		}

		setLoading(true);
		if (selectedCate || all) {
			setSearchPro([]);
			setSearchVal("");
		}
		CoreHttpHandler.request(
			"products",
			"allProducts",
			{
				limit: currentParams.limit,
				page: page ? page : currentParams.page,
				id: typeof selectedCate !== "undefined" ? selectedCate : selectedCategory,
				// 				id: selectedCate ? selectedCate : "",
				sortBy: sortBy ? sortBy : "desc",
				sub_categories: subCat? subCat?.toString(): null

			},
			(response) => {
				setLoading(false);
				const res = response.data.data.data;
				setTotalItems(res.totalItems);
				setProducts(res);
			},
			(err) => {
				setLoading(false);
			}
		);
	});
	
	useEffect(() => {
		ScrollUp();
	}, [location.pathname]);
	return (
		<>
			<ToastContainer limit={1} />
			<Routes
				getProducts={getProducts}
				loading={loading}
				setLoading={setLoading}
				products={products}
				searchPro={searchPro}
				setSearchPro={setSearchPro}
				setProducts={setProducts}
				searchVal={searchVal}
				setSearchVal={setSearchVal}
				totalpages={totalItems}
				setCurrentParams={setCurrentParams}
				sortBy={sortBy}
				currentParams={currentParams}
				setSortBy={setSortBy}
			/>
		</>
	);
};

export default App;
