import React from 'react'
import UserProfileDetails from './components/Userprofilecomponent/UserProfileDetails'

const Userprofile = () => {
  return (
    <>
      <div className='content_wrap'>
        <UserProfileDetails />
      </div>
    </>
  )
}

export default Userprofile