import React from "react";
import {Faqsec1} from './components/Faqcomponent';

const Faq = () => {
  return (
    <>
      <div id="faq_main">
        <div className="content_wrap">
          <Faqsec1 />
        </div>
      </div>
    </>
  );
};

export default Faq;