import React from 'react'
import {Orderhis} from './components/Ordercomponent';

const Orderhistory = () => {
  return (
    <>
      <div className='content_wrap'>
        <Orderhis />
      </div>
    </>
  )
}

export default Orderhistory