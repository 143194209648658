import { NavLink, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
	const token = localStorage.getItem("user_token")
		? localStorage.getItem("user_token")
		: null;

	return (
		<>
			{token ? (
				<Outlet />
			) : (
				<div
					className='flex flex-col items-center justify-center'
					style={{
						minHeight: "79vh",
					}}>
					<h1 className='text-2xl font-bold text-gray-800'>
						You are not logged in
					</h1>
					<NavLink
						to='/login'
						className='text-blue-600'>
						Login
					</NavLink>
				</div>
			)}
		</>
	);
};

export default ProtectedRoute;
