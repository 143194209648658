import React, { useEffect, useState } from "react";
import { ProductImage } from "../../../reauseble";
import { useLocation, Link, useNavigate } from "react-router-dom";
import moment from "moment";
import parser from "html-react-parser";
import CoreHttpHandler from "../../../http/services/CoreHttpHandler";

const UserProfileDetails = () => {

	const [userData,setUserData] = useState([])
	const { state } = useLocation();
	const navigate = useNavigate();
	useEffect(() => {
		CoreHttpHandler.request(
			"users",
			"userprofile",
			{},
			(response) => {
				setUserData(response.data.data)
			},
			(err) => {
				console.log(err);
			}
		);
		// if (!state) {
		// 	navigate("/userprofile");
		// }

	}, []);
	
	return (
		<div className='content_wrap'>
			<section className='order_details'>
				<div className='container'>
							<h1 style={{color:"white",textAlign:"center"}}>User Details</h1>
						<div className='card'>
                        <form style={{display:"flex",flexDirection:"column",justifyContent:"space-evenly",width:"100%",padding:"1em 10em"}}>
								
								<h4 style={{color:"white",margin:"1em 0em"}}>User Name: </h4>
                                <input type="text" name="" value={userData.username}  disabled/>
								<h4 style={{color:"white",margin:"1em 0em"}}>Email: </h4>
                                <input style={{textTransform:"none !important"}} type="text" name="" value={userData.email} disabled/>

                        </form>
						</div>
						
					</div>
			</section>
		</div>
	);
};

export default UserProfileDetails;
